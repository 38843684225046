import * as React from 'react';
import { Helmet } from 'react-helmet';
import tabLogoDark from '../images/tab-logo-dark.svg';
import tabLogoLight from '../images/tab-logo-light.svg';

const Head = ({ pageTitle }) => (
  <Helmet>
    <title>{pageTitle}</title>
    <link rel="icon" type="image/x-icon" media="(prefers-color-scheme:dark)" href={tabLogoDark} />
    <link rel="icon" type="image/x-icon" media="(prefers-color-scheme:light)" href={tabLogoLight} />
  </Helmet>
);

export default Head;
