// extracted by mini-css-extract-plugin
export var navLogo = "navbar-module--navLogo--27I2k";
export var navFont = "navbar-module--navFont--3c5I4";
export var myNav = "navbar-module--myNav--EYImj";
export var navItem = "navbar-module--navItem--3VjLF";
export var navLink = "navbar-module--navLink--3_5rg";
export var dropdown = "navbar-module--dropdown--hqmZU";
export var dropdownMenu = "navbar-module--dropdownMenu--2NG-6";
export var dropdownItem = "navbar-module--dropdownItem--2oNmx";
export var navDropdown = "navbar-module--navDropdown--39Utp";
export var navLinkList = "navbar-module--navLinkList--3Cv36";