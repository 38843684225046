import { Link } from 'gatsby';
import window from 'global';
import { Turn as Hamburger } from 'hamburger-react';
import * as React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import navLogo from '../images/nav-logo3.svg';
import * as navStyles from '../styles/mobile-nav.module.css';
import * as styles from '../styles/toggle-text.module.css';

const MobileNavbar = () => {
  let prevScrollpos = window.pageYOffset;

  /**
   * Here is the function for hiding/showing navbar on scroll
   */

  // eslint-disable-next-line func-names
  window.onscroll = function () {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos === 0) {
      document.getElementById('myNav').removeAttribute('style');
    } else if (prevScrollpos > currentScrollPos) {
      document.getElementById('myNav').style.top = '0';
      document.getElementById('myNav').style.backgroundColor = 'rgba(0, 0, 0, 0.9)';
    } else {
      document.getElementById('myNav').style.top = '-20vh';
    }
    prevScrollpos = currentScrollPos;
  };

  return (
    <Navbar id="myNav" className={`${navStyles.navFont} ${navStyles.myNav}`} expand="lg">
      <Navbar.Brand>
        <Link to="/" className={navStyles.navBrand}>
          <img src={navLogo} alt="TechBoss Logo" className={navStyles.navLogo} />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="myNav" style={{ marginRight: '6%' }}>
        <Hamburger direction="right" color="white" size="28" />
      </Navbar.Toggle>
      <Navbar.Collapse id="myNav" className={navStyles.collapseContainer}>
        <Nav className={`${navStyles.navItem} ${navStyles.dropdown} ${navStyles.navFont}`}>
          <div style={{ display: 'flex' }}>
            <Link
              to="/curriculum"
              className={`${navStyles.navLink}`}
            >
              CURRICULUM
            </Link>
            <button
              type="button"
              id="dropdownIcon"
              className={`dropdown-icon ${styles.dropdownIcon} ${styles.navLinkFont}`}
              onClick={() => {
                if (document.getElementById('dropdownMenu').style.display === 'none') {
                  document.getElementById('dropdownMenu').style.display = 'block';
                  document.getElementById('dropdownIcon').style.transform = 'rotate(180deg)';
                } else {
                  document.getElementById('dropdownMenu').style.display = 'none';
                  document.getElementById('dropdownIcon').style.transform = 'rotate(0deg)';
                }
              }}
            >
              ▼
            </button>
          </div>
          <div id="dropdownMenu" className={navStyles.dropdownMenu} style={{ display: 'none', height: '30vh' }}>
            <Link to="/nexgen" className={`dropdown-item ${navStyles.dropdownItem}`}>NEXGEN COMPUTING</Link>
            <Link to="/python" className={`dropdown-item ${navStyles.dropdownItem}`}>PYTHON CHIEF</Link>
            <Link to="/java" className={`dropdown-item ${navStyles.dropdownItem}`}>JAVA MASTER</Link>
          </div>
        </Nav>
        <Nav className={navStyles.navItem}>
          <Link
            to="/corporate"
            className={`${navStyles.navLink}`}
          >
            CORPORATE
          </Link>
        </Nav>
        <Nav className={navStyles.navItem}>
          <Link
            to="/blog"
            className={`${navStyles.navLink}`}
          >
            BLOGS
          </Link>
        </Nav>
        <Nav className={navStyles.navItem}>
          <Link
            to="/contact"
            className={`${navStyles.navLink}`}
          >
            CONTACT US
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MobileNavbar;
