import { Link } from 'gatsby';
import window from 'global';
import * as React from 'react';
import navLogo from '../images/nav-logo3.svg';
import * as navStyles from '../styles/navbar.module.css';

const Navbar = () => {
  let prevScrollpos = window.pageYOffset;

  /**
   * Here is the function for hiding/showing navbar on scroll
   */

  // eslint-disable-next-line func-names
  window.onscroll = function () {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos === 0) {
      document.getElementById('myNav').removeAttribute('style');
    } else if (prevScrollpos > currentScrollPos) {
      document.getElementById('myNav').style.top = '0';
      document.getElementById('myNav').style.backgroundColor = 'rgba(0, 0, 0, 0.9)';
    } else {
      document.getElementById('myNav').style.top = '-20vh';
    }
    prevScrollpos = currentScrollPos;
  };

  return (
    <nav id="myNav" className={`navbar navbar-expand-lg navbar-dark ${navStyles.myNav}`}>
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img src={navLogo} alt="TechBoss Logo" className={navStyles.navLogo} />
        </Link>
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className={`nav-item ${navStyles.dropdown} ${navStyles.navFont} ${navStyles.navItem}`}>
            <Link
              to="/curriculum"
              className={`${navStyles.navLink}`}
            >
              CURRICULUM
            </Link>
            <div className={navStyles.dropdownMenu}>
              <Link to="/nexgen" className={`dropdown-item ${navStyles.dropdownItem}`}>NEXGEN COMPUTING</Link>
              <Link to="/python" className={`dropdown-item ${navStyles.dropdownItem}`}>PYTHON CHIEF</Link>
              <Link to="/java" className={`dropdown-item ${navStyles.dropdownItem}`}>JAVA MASTER</Link>
            </div>
          </li>
          <li className={`nav-item ${navStyles.navFont} ${navStyles.navItem}`}>
            <Link to="/corporate" className={`${navStyles.navLink}`}>CORPORATE</Link>
          </li>
          <li className={`nav-item ${navStyles.navFont} ${navStyles.navItem}`}>
            <Link to="/blog" className={`${navStyles.navLink}`}>BLOGS</Link>
          </li>
          <li className={`nav-item ${navStyles.navFont}`}>
            <Link to="/contact" className={`${navStyles.navLink}`}>CONTACT US</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
