// extracted by mini-css-extract-plugin
export var navLogo = "mobile-nav-module--navLogo--dURCG";
export var navFont = "mobile-nav-module--navFont--13giC";
export var myNav = "mobile-nav-module--myNav--3jX8a";
export var navItem = "mobile-nav-module--navItem--3LSLA";
export var navLink = "mobile-nav-module--navLink--1WTvQ";
export var dropdown = "mobile-nav-module--dropdown--3Jiaq";
export var dropdownMenu = "mobile-nav-module--dropdownMenu--1mq7H";
export var dropdownItem = "mobile-nav-module--dropdownItem--1BfKG";
export var navDropdown = "mobile-nav-module--navDropdown--1tToa";
export var dropdownIcon = "mobile-nav-module--dropdownIcon--xwfCA";
export var collapseContainer = "mobile-nav-module--collapseContainer--1Ufxg";
export var navBrand = "mobile-nav-module--navBrand--19hS-";